import { Fill, Style, Stroke, RegularShape } from 'ol/style';
import Text from 'ol/style/Text';

const fill = new Fill({ color: 'red' });
const stroke = new Stroke({ color: 'black', width: 0.75 });

const pointStyleFunction = function (feature, resolution) {
	return new Style({
		image: new RegularShape({
			fill: fill,
			stroke: stroke,
			points: 4,
			radius: 1,
			angle: Math.PI / 4
		}),
		text: createTextStyle(feature, resolution)
	});
};
const getBollardText = function (feature, resolution) {
	let text = '';
	if (resolution < 2) {
		text = feature.get('bolcode');
	}
	return text;
};
const createTextStyle = function (feature, resolution) {
	return new Text({
		text: getBollardText(feature, resolution),
		stroke: new Stroke({
			color: 'white',
			width: 2
		}),
		overflow: true,
		offsetY: -10
	});
};

export default pointStyleFunction;
