import Feature from 'ol/Feature';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import mapUtils from '../mapUtils';
import mapFeatureStyleUtils from '../mapFeatureStyleUtils';
import CalcularPuntoAtraqueWorker from 'worker-loader!./../workers/calcularPuntoAtraque';
import BoatBuilder from '../builder/boatShapeBuilder';
import WKT from 'ol/format/WKT';
import Circle from 'ol/geom/Circle';

import {
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	SELECT_STOP_LAYER_ID,
	STOP_INSTANT_LAYER_ID
} from '@/components/operations/map/constants/layers';

import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

/**
 * It has de responsability to create Openlayers Layer Features with the dimensions of a vessel given its stops attributes.
 * 1- First set the berthlayer @method setBerthLayer
 * 2- Set the bollardLayer @method setBollardLayer
 * 3- Call the  @method processStops with the stops array as an argument
 */
class StopsFeatureCreator {
	berthBoatsMap = {};
	/**
	 * @property {Number} workerController
	 * it controlls the worker index for the next processing stop, used internally only
	 */

	workerController = 0;

	/**
	 * @property {Number} numOfWorkers
	 * it defines the number of workers for calcularPuntoAtraque
	 */
	numOfWorkers = 1;
	/**
	 * @property {Number} distAbarloar
	 * it defines the amount of meters in projection to leave between boats
	 */
	distAbarloar = 10;
	/**
	 * @property {Array} calcularPuntoAtraqueWorker
	 * the array containing the workers st by the numOfWorkers
	 */
	calcularPuntoAtraqueWorker = null;

	// Prevision layers

	stopsVectorAcceptedSource = new VectorSource({
		features: []
	});

	stopsAcceptedLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: STOP_ACCEPTED_LAYER_ID
		},
		source: this.stopsVectorAcceptedSource
	});

	stopsVectorPlannedSource = new VectorSource({
		features: []
	});

	stopsPlannedLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: STOP_PLANNED_LAYER_ID
		},
		source: this.stopsVectorPlannedSource
	});

	stopsVectorAuthorizedSource = new VectorSource({
		features: []
	});

	stopsAuthorizedLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: STOP_AUTHORIZED_LAYER_ID
		},
		source: this.stopsVectorAuthorizedSource
	});

	stopsVectorConfirmedSource = new VectorSource({
		features: []
	});

	stopsConfirmedLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: STOP_CONFIRMED_LAYER_ID
		},
		source: this.stopsVectorConfirmedSource
	});

	stopsVectorInitiatedSource = new VectorSource({
		features: []
	});

	stopsInitiatedLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: STOP_INITIATED_LAYER_ID
		},
		source: this.stopsVectorInitiatedSource
	});

	// Instant layers
	stopsVectorInstantSource = new VectorSource({
		features: []
	});

	stopsInstantLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: STOP_INSTANT_LAYER_ID
		},
		source: this.stopsVectorInstantSource,
		visible: false // Hacemos la capa inicialmente en no visible
	});

	// Select layer
	stopSelectLayerSource = new VectorSource({
		features: []
	});

	stopSelectLayer = new VectorLayer({
		source: this.stopSelectLayerSource,
		properties: {
			id: SELECT_STOP_LAYER_ID
		},
		zIndex: 200
	});

	modeVisualization = null;
	statusCodes = [];
	viewCodes = [];
	idStopSelected = null;
	searchTextVessel = '';
	berthLayer = null;
	bollardLayer = null;
	berthFeatures = null;
	stopsToProcess = 0;
	/**
	 * @param {Function} onStopsProcessed Callback function executed when all stops are in loaded to the Map
	 * @param {Object} modeVisualization Tells de mode visualization map
	 * @param {Number} numOfWorkers (Default to 1)
	 * @param {Number} distAbarloar (default to 10m)
	 */
	constructor(onStopsProcessed, modeVisualization, numOfWorkers = 1, distAbarloar = 10) {
		this.modeVisualization = modeVisualization;
		this.numOfWorkers = numOfWorkers;
		this.calcularPuntoAtraqueWorker = [];
		this.distAbarloar = distAbarloar;
		this.onStopsProcessed = onStopsProcessed;
		for (var i = 0; i < this.numOfWorkers; i++) {
			this.calcularPuntoAtraqueWorker.push(new CalcularPuntoAtraqueWorker());
		}
	}

	setIdStopSelected(isStop) {
		this.idStopSelected = isStop;
	}

	setStatusCodes(statusCodes) {
		this.statusCodes = statusCodes;
	}

	setViewCodes(viewCodes) {
		this.viewCodes = viewCodes;
	}

	setSearchingTextVessel(searchtext) {
		this.searchTextVessel = searchtext;
	}

	setModeVisualization(value) {
		this.modeVisualization = value;
	}

	setBerthLayer(berthLayer) {
		this.berthLayer = berthLayer;
	}

	setBollardLayer(bollardLayer) {
		this.bollardLayer = bollardLayer;
	}

	getAcceptedLayer(visibility) {
		if (visibility != undefined) {
			this.stopsAcceptedLayer.setVisible(visibility);
		}
		return this.stopsAcceptedLayer;
	}

	getPlannedLayer(visibility) {
		if (visibility != undefined) {
			this.stopsPlannedLayer.setVisible(visibility);
		}
		return this.stopsPlannedLayer;
	}

	getAuthorizedLayer(visibility) {
		if (visibility != undefined) {
			this.stopsAuthorizedLayer.setVisible(visibility);
		}
		return this.stopsAuthorizedLayer;
	}

	getConfirmedLayer(visibility) {
		if (visibility != undefined) {
			this.stopsConfirmedLayer.setVisible(visibility);
		}
		return this.stopsConfirmedLayer;
	}

	getInitiatedLayer(visibility) {
		if (visibility != undefined) {
			this.stopsInitiatedLayer.setVisible(visibility);
		}
		return this.stopsInitiatedLayer;
	}

	getInstantLayer() {
		return this.stopsInstantLayer;
	}

	getStopSelectLayer() {
		return this.stopSelectLayer;
	}

	getStopLayerById(idLayer) {
		switch (idLayer) {
			case STOP_ACCEPTED_LAYER_ID:
				return this.getAcceptedLayer();
			case STOP_PLANNED_LAYER_ID:
				return this.getPlannedLayer();
			case STOP_AUTHORIZED_LAYER_ID:
				return this.getAuthorizedLayer();
			case STOP_CONFIRMED_LAYER_ID:
				return this.getConfirmedLayer();
			case STOP_INITIATED_LAYER_ID:
				return this.getInitiatedLayer();
			case STOP_INSTANT_LAYER_ID:
				return this.getInstantLayer();
			default:
				return null;
		}
	}

	/**
	 *
	 * @param {Object} stopThe stop object to be displayed as a Boat
	 * @param {Object} puntoAtraque The centroid of the boat to create it (only called by recursion, when method @_needsAbarloarBoat returns a number)
	 */

	async _processStop(stop, puntoAtraque, distAbarloar) {
		try {
			const berthFeat = this.berthLayer.getSource().getFeatureById(stop.berthid);
			let bolIni =
				stop.bollardini !== null && stop.bollardini !== undefined ? this.bollardLayer.getSource().getFeatureById(stop.bollardini) : false;
			let bolEnd =
				stop.bollardend !== null && stop.bollardend !== undefined ? this.bollardLayer.getSource().getFeatureById(stop.bollardend) : false;
			let bolIniFindedFlag = false;
			let bolEndFindedFlag = false;
			let middePoint = berthFeat.get('middle');
			const lengthMercator = mapUtils.getMetersMercator(middePoint.y, stop.vessellength);
			const distanceRatioForThisLat = lengthMercator / stop.vessellength;
			const beamMercator = stop.vesselbeam * distanceRatioForThisLat;
			if (!bolIni) {
				bolIni = berthFeat.get('iniPoint');
			} else {
				bolIni = mapUtils.createPoint(...bolIni.getGeometry().flatCoordinates);
				bolIniFindedFlag = true;
			}
			if (!bolEnd) {
				bolEnd = berthFeat.get('endPoint');
			} else {
				bolEndFindedFlag = true;
				bolEnd = mapUtils.createPoint(...bolEnd.getGeometry().flatCoordinates);
			}
			if (bolIniFindedFlag && bolEndFindedFlag) {
				middePoint = mapUtils.getMiddlePoint(bolIni, bolEnd);
			}

			var tipoAtraque = 'C';
			var dirAtraque = 'E';

			if (stop.docksideways != null) {
				if (stop.docksideways) {
					// COSTADO
					tipoAtraque = 'C';
					dirAtraque = stop.portstarboard || 'E';
				} else {
					// PUNTA
					tipoAtraque = 'P';
					dirAtraque = stop.bowstern ? stop.bowstern : 'B';
				}
			}

			var argumentObject = {
				fid: stop.id,
				offset: 20 * distanceRatioForThisLat,
				ptoMedio: middePoint,
				dirAtraque: dirAtraque, // E: estribor, B: babor (para caso de costado) B: proa, S: popa (caso de punta)
				tipoAtraque: tipoAtraque, // C: costado,  P: punta
				tiposAtraque: this.tiposAtraque,
				agua: berthFeat.get('seaside'),
				alfa: berthFeat.get('slope'),
				esloraMercatorDiv2: lengthMercator,
				mangaMercatorDiv2: beamMercator,
				distAbarloar: distAbarloar * distanceRatioForThisLat || this.distAbarloar * distanceRatioForThisLat,
				//attributes: stop,
				attributes: Object.assign({}, stop),
				puntoAtraque
			};
			stop.alfa = argumentObject.alfa;
			const worker = this._getWorker(this.calcularPuntoAtraqueWorker);
			worker.postMessage(argumentObject);
			if (worker.onmessage === undefined || worker.onmessage === null) {
				worker.onmessage = (msg) => {
					if (!msg.data) {
						console.log('No message data boat');
						this._checkStopsProcessed();
						//return self.failCreateBoat(self.errores.noPuntoAtraque, feature);
					} else {
						this._createBoat(msg.data, this.getStopLayerById(msg.data.attributes.idLayer));
					}
				};
			}
		} catch (e) {
			console.log(stop);
			console.log('muelle no encontrado: ' + stop.berthid);
			this._checkStopsProcessed();
		}
	}

	async processStops(stops) {
		console.log('processStops: ' + stops.length);
		// Cada vez que llamamos a processStops sumamos los stops a procesar
		this.stopsToProcess = stops.length;
		//stops.forEach((stop) => this._processStop(stop));
		const self = this;
		// TODO: en vez de usar un timeout hay que ver la manera de hacer la llamada sincrona.
		stops.forEach(function (stop, index) {
			setTimeout(function () {
				if (stop.vesselbeam == null || stop.vessellength == null) {
					console.log('No tenemos las dimensiones del barco para la escala: ' + stop.portcallnumber);
					self._checkStopsProcessed();
				} else {
					self._processStop(stop);
				}
			}, 100 * (index + 1));
		});
	}

	_onStopsProcessed(callback) {
		this.onStopsProcessed();
	}

	_createBoat(stopObj, layer) {
		const stop = stopObj.attributes;
		if (isNaN(stopObj.poly.boat[0][0])) {
			console.log('Stop with no coordinates boat: ' + stopObj.attributes.vesselname + ' idLayer: ' + stopObj.attributes.idLayer);
			this._checkStopsProcessed();
			return;
		}

		const boatPolygonsObj = BoatBuilder.createBoat(stopObj.poly.boat, stopObj.poly.bounds);
		const boatFeature = new Feature(boatPolygonsObj.boat);

		const boatBoundsGeometry = boatPolygonsObj.bounds;
		stop.puntoAtraque = stopObj.puntoAtraque;
		stop.polyBounds = stopObj.poly.boat;
		stop.lon = stopObj.puntoAtraque.x;
		stop.lat = stopObj.puntoAtraque.y;
		const searchTextLower = typeof this.searchTextVessel === 'string' ? this.searchTextVessel.toLowerCase() : '';

		const isVesselNameMatch = (stop.vesselname ?? '').toLowerCase().includes(searchTextLower);
		const isMMSIMatch = (stop.mmsi ?? '').toString().toLowerCase().includes(searchTextLower);
		const isTypeDescriptionMatch = (stop.vesseltypedescription ?? '').toLowerCase().includes(searchTextLower);

		const isMatch = isVesselNameMatch || isMMSIMatch || isTypeDescriptionMatch;

		if (this.statusCodes.length > 0) {
			const isStatusMatch = this.statusCodes.includes(stop.statusid) || (this.viewCodes && this.viewCodes.includes(stop.statusid));
			stop.featureVisible = isMatch && isStatusMatch;
		} else {
			stop.featureVisible = isMatch;
		}

		if (this.idStopSelected != null) {
			stop.clickable = this.idStopSelected == stop.id;
		} else {
			stop.clickable = this.statusCodes.includes(stop.statusid) ? true : false;
		}

		boatFeature.setProperties(stop);

		const berthid = stop.berthid;
		if (Object.prototype.hasOwnProperty.call(this.berthBoatsMap, berthid)) {
			const abarloar = this._needsAbarloarBoat(stop, boatPolygonsObj.bounds, berthid);
			if (abarloar !== false) {
				/*  if (abarloar === -1) {
                        return;
                    } */
				this._processStop(stopObj.attributes, stopObj.puntoAtraque, abarloar + this.distAbarloar);
				return;
			}
		}
		//Aplicar estilo a barcos.
		const resolution = ol2map.getMapInstance().getView().getResolution();
		mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
			boatFeature,
			resolution,
			boatPolygonsObj.boat,
			stopObj.puntoAtraque.x,
			stopObj.puntoAtraque.y,
			this.modeVisualization
		);
		boatFeature.changed();

		layer.setOpacity(0.75);
		layer.getSource().addFeature(boatFeature);
		this._checkStopsProcessed();
		this._addBoatToBerthBoatsMap(stop, boatBoundsGeometry);
		//this.buquesFeatures.push(buqueFeature);
	}

	_checkStopsProcessed() {
		if (this.stopsToProcess !== 0) {
			this.stopsToProcess--;
			if (this.stopsToProcess === 0) {
				this._onStopsProcessed();
			}
		}
	}

	_getWorker(workersArray) {
		this.workerController++;
		if (this.workerController === workersArray.length) {
			this.workerController = 0;
		}
		return workersArray[this.workerController];
	}

	_addBoatToBerthBoatsMap(stop, boatBounds) {
		if (!Object.prototype.hasOwnProperty.call(this.berthBoatsMap, stop.berthid)) {
			this.berthBoatsMap[stop.berthid] = [];
		}
		const tipatr = stop.tipatr;
		let offset = stop.vesselbeam;
		/* if (tipatr !== 'C' && tipatr !== '1' && tipatr !== '2' && tipatr !== 'S') {
            offset = stop.vessellength;
        } */
		if (tipatr === 'P') {
			offset = stop.vessellength;
		}
		this.berthBoatsMap[stop.berthid].push({ idStop: stop.id, bounds: boatBounds, offset });
	}

	_needsAbarloarBoat(stop, boatFeature, berthid) {
		for (let length = this.berthBoatsMap[berthid].length, i = 0; i < length; i++) {
			const exitentBoatiInBerthBoundsFeature = this.berthBoatsMap[berthid][i];
			//Si soy el mismo barco puedo solaparme. (esto es para cuando movemos un barco)
			if (stop.id == exitentBoatiInBerthBoundsFeature.idStop) {
				continue;
			}
			const intersectsExistentBoat = mapUtils.overlaps(exitentBoatiInBerthBoundsFeature.bounds, boatFeature);
			if (intersectsExistentBoat === true) {
				return exitentBoatiInBerthBoundsFeature.offset || false;
			}
		}
		return false;
	}
	/**
	 *
	 * @param {Array<Berths>} berths The arrayList as it comes from the operationscarto
	 * @returns {Array<Feature>} berthsFeatures Openlayers Features for a vector layer
	 */

	processBerths(berths) {
		const format = new WKT();
		var berthFeatures = berths
			.map((berth) => {
				try {
					// Angulo perpendicular
					berth.slope = Math.PI / 2 - berth.azimuth * (Math.PI / 180);
					//dejamos que caiga en rango de 0 a 2PI
					if (berth.slope < 0) {
						berth.slope = berth.slope + 2 * Math.PI;
					}
					const feat = format.readFeature(berth.thegeom, {
						dataProjection: 'EPSG:4326',
						featureProjection: 'EPSG:3857'
					});
					const firstBerthVertex = mapUtils.createPoint(...feat.getGeometry().flatCoordinates);
					const lastBerthVertex = mapUtils.createPoint(...feat.getGeometry().flatCoordinates.slice(2));
					const berthMiddlePoint = mapUtils.getMiddlePoint(firstBerthVertex, lastBerthVertex);
					berth.middle = berthMiddlePoint;
					berth.iniPoint = firstBerthVertex;
					berth.endPoint = lastBerthVertex;
					feat.setProperties(berth);
					feat.setId(berth.id);
					feat.type = 'LineString';
					return feat;
				} catch (e) {
					console.info('muelle no añadido: ' + berth.id);
				}
			})
			.filter((feat) => !feat === false);

		return berthFeatures;
	}
	/**
	 *
	 * @param {Array<Bollards>} bollards The arrayList as it comes from the operationscarto
	 * @returns {Array<Feature>} bollardsFeatures Openlayers Features for a vector layer
	 */

	processBollards(bollards) {
		const format = new WKT();
		const berthIds = Object.keys(bollards);
		const bollardFeatures = [];
		berthIds.forEach((berthId) => {
			bollards[berthId].forEach((bol) => {
				try {
					const feat = format.readFeature(bol.thegeom, {
						dataProjection: 'EPSG:4326',
						featureProjection: 'EPSG:3857'
					});
					feat.setProperties(bol);
					feat.setId(bol.id);
					bollardFeatures.push(feat);
					bol.x = feat.values_.geometry.flatCoordinates[0];
					bol.y = feat.values_.geometry.flatCoordinates[1];
				} catch (e) {
					console.info('bolardo no añadido: ' + bol.id);
				}
			});
		});
		return bollardFeatures;
	}

	terminateWorkers() {
		this.calcularPuntoAtraqueWorker.forEach((worker) => {
			worker.terminate();
		});
	}

	clearBerthBoatsMap() {
		this.berthBoatsMap = {};
	}

	clearStops(idLayer) {
		this.getStopLayerById(idLayer).getSource() && this.getStopLayerById(idLayer).getSource().clear();
	}

	addFeatureSelectStop(coordinates, vessellength) {
		const feature = new Feature(new Circle([coordinates[0], coordinates[1]], vessellength));
		const properties = {
			idLayer: SELECT_STOP_LAYER_ID
		};
		feature.setProperties(properties);
		this.stopSelectLayer.getSource().addFeature(feature);
	}

	clearSelectStops() {
		this.stopSelectLayer.getSource() && this.stopSelectLayer.getSource().clear();
	}
}
export default StopsFeatureCreator;
