<template>
	<div class="tab" @click="openPanel" :class="{ tabactive: isActive }">
		<v-icon :color="color" v-if="!this.options.data.isAis" small>fas fa-circle</v-icon>
		<div v-else small class="centered">
			<svg-icon v-if="isActive" group="tabs" icon="tabnaisblue" :width="22" />
			<svg-icon v-else group="tabs" icon="tabais" :width="22" />
		</div>
	</div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon';

/** Utils */
import colorUtils from '@/components/operations/map/styles/colorUtils';

/** Constants */
import { MAP_AIS_ID, TYPE_VIS, STATUS_VIS } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

/** Mixins */
import StopsServiceMixin from '@/mixins/StopsServiceMixin';

export default {
	name: 'tabimage',

	components: {
		SvgIcon
	},

	mixins: [StopsServiceMixin],

	props: {
		options: {
			type: Object,
			required: true
		},
		currentmap: {
			type: String,
			required: true
		}
	},

	computed: {
		isActive() {
			return this.activePanel === this.options.panelId;
		},
		isCurrentMapAis() {
			return this.currentmap == MAP_AIS_ID;
		},
		activePanel() {
			if (this.isCurrentMapAis) {
				return this.$store.getters.activePanelAIS;
			}
			return this.$store.getters.activePanelPeriods;
		},
		defaultBackgroundColor() {
			return this.$store.getters.defaultBackgroundColor;
		},
		color() {
			if (this.isCurrentMapAis) {
				if (this.$store.getters.getMapAisStyleVisualization == TYPE_VIS) {
					return this.options.data.vesseltype
						? this.$store.getters.stopsTypeValues[this.options.data.vesseltype].color
						: this.defaultBackgroundColor;
				} else if (this.$store.getters.getMapAisStyleVisualization == STATUS_VIS) {
					let color = this.$store.getters.stopsStatusValues.filter((status) => status.stopstatuscode == this.options.data.statusid)[0]
						.color;
					return color ? color : this.defaultBackgroundColor;
				} else {
					return this.$store.getters.stopsOperationsValues
						? colorUtils.getColorOperationsStop(this.options.data)
						: this.defaultBackgroundColor;
				}
			} else {
				if (this.$store.getters.getMapPeriodsStyleVisualization == TYPE_VIS) {
					return this.options.data.vesseltype
						? this.$store.getters.stopsTypeValues[this.options.data.vesseltype].color
						: this.defaultBackgroundColor;
				} else if (this.$store.getters.getMapPeriodsStyleVisualization == STATUS_VIS) {
					let color = this.$store.getters.stopsStatusValues.filter((status) => status.stopstatuscode == this.options.data.statusid)[0]
						.colorhex;
					return color ? color : this.defaultBackgroundColor;
				} else {
					return this.$store.getters.stopsOperationsValues
						? colorUtils.getColorOperationsStop(this.options.data)
						: this.defaultBackgroundColor;
				}
			}
		}
	},

	methods: {
		openPanel() {
			if (this.currentmap == MAP_AIS_ID) {
				this.$store.commit('addDynamicPanelAIS', this.options);
				this.$store.commit('setActivePanelAIS', this.options);
			} else {
				if (this.options.instantPanel) {
					this.changeOpacityByPanel(this.options, this.options);
				}
				this.$store.commit('addDynamicPanelPeriods', this.options);
				this.$store.commit('setActivePanelPeriods', this.options);
			}
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/app-variables.pcss';

.tab {
	width: 40px;
	height: 40px;
	background-color: var(--low);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom: 1px solid #bbb;
	font-size: 16px;
	& .centered {
		padding-top: 8px;
		width: 30px;
		margin: auto;
		margin-right: 2px;
	}
	& i {
		padding-top: 12px;
		padding-left: 11px;
	}
	&.tabactive {
		background-color: var(--very-low);
		i {
			color: '#00FF00';
		}
	}
	&:hover {
		cursor: pointer;
	}
}
</style>
