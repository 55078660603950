import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { LineString } from 'ol/geom';
import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature';
import trackEventVesselLineStyle from '../styles/trackEventVesselLineStyle';
import trackEventVesselLinePointStyle from '../styles/trackEventVesselLinePointStyle';
import { scaleLinear } from 'd3-scale';

import { AIS_TRACK_EVENT_VESSEL_LINE_LAYER_ID, AIS_TRACK_EVENT_VESSEL_LINE_POINT_LAYER_ID } from '@/components/operations/map/constants/layers';

class AISTrackEventVesselLineFeatureCreator {
	vectorSourceLine = null;
	vectorLayerLine = new VectorLayer({
		declutter: false,
		properties: {
			id: AIS_TRACK_EVENT_VESSEL_LINE_LAYER_ID
		},
		source: this.vectorSourceLine
	});

	vectorSourcePoint = null;
	vectorLayerPoint = new VectorLayer({
		declutter: false,
		properties: {
			id: AIS_TRACK_EVENT_VESSEL_LINE_POINT_LAYER_ID
		},
		source: this.vectorSourcePoint,
		zIndex: 350
	});

	constructor() {}

	getTrackEventVesselLineLayer() {
		return this.vectorLayerLine;
	}

	getTrackEventVesselLinePointLayer() {
		return this.vectorLayerPoint;
	}

	/**
	 *
	 * @param {Object} trackData The AIS track data to display waypoints
	 */
	_processEventVesselLineTrack(trackData, vistrack) {
		try {
			this.drawTrack(trackData, vistrack);
		} catch (e) {
			console.log(e);
		}
	}

	drawTrack(trackData, vistrack) {
		// Linestring
		if (vistrack.visTrack) {
			const locations = trackData.map((element) => {
				return [element.lon, element.lat];
			});

			const route = new LineString(locations).transform('EPSG:4326', 'EPSG:3857');

			const routeFeature = new Feature({
				geometry: route
			});

			this.vectorSourceLine = new VectorSource({
				features: [routeFeature]
			});

			this.vectorLayerLine.setSource(this.vectorSourceLine);
			this.vectorLayerLine.setStyle(trackEventVesselLineStyle);
		}

		// Point event
		if (vistrack.visPosAis) {
			// Get the minium and max update date
			const [minUpdateDate, maxUpdateDate] = this.calculateMinAndMaxTimes(trackData);

			const pointFeatures = [];
			for (let i = 0; i < trackData.length; i++) {
				var rotation = 0;
				if (i == trackData.length - 1) {
					// Ultimo punto
					const segmentStart = [trackData[i - 1].lon, trackData[i - 1].lat];
					const segmentEnd = [trackData[i].lon, trackData[i].lat];
					const dx = segmentEnd[0] - segmentStart[0];
					const dy = segmentEnd[1] - segmentStart[1];
					rotation = Math.atan2(dy, dx) + Math.PI / 2;
				} else {
					const segmentStart = [trackData[i].lon, trackData[i].lat];
					const segmentEnd = [trackData[i + 1].lon, trackData[i + 1].lat];
					const dx = segmentEnd[0] - segmentStart[0];
					const dy = segmentEnd[1] - segmentStart[1];
					rotation = Math.atan2(dy, dx) + Math.PI / 2;
				}

				// Create geom point
				const pointGeom = new Point([trackData[i].lon, trackData[i].lat]).transform('EPSG:4326', 'EPSG:3857');
				// Create feature
				const pointFeature = new Feature({
					geometry: pointGeom
				});
				// Get color range by update time
				const color = this.colorScale(trackData[i].updateiso, minUpdateDate, maxUpdateDate);
				// Set properties
				const properties = {
					rotation: -rotation,
					speed: trackData[i].speed,
					aisupdatedate: trackData[i].updateiso,
					isEventLine: true,
					featureVisible: true,
					shipname: trackData[i].shipname,
					color: color
				};
				pointFeature.setProperties(properties);
				pointFeatures.push(pointFeature);
			}

			this.vectorSourcePoint = new VectorSource({
				features: pointFeatures
			});

			this.vectorLayerPoint.setSource(this.vectorSourcePoint);
			this.vectorLayerPoint.setStyle(trackEventVesselLinePointStyle);
		}
	}

	clearTrack() {
		this.vectorLayerLine.getSource() && this.vectorLayerLine.getSource().clear();
		this.vectorLayerPoint.getSource() && this.vectorLayerPoint.getSource().clear();
	}

	calculateMinAndMaxTimes(trackData) {
		// Convertir las fechas a objetos Date
		const fechasObjeto = trackData.map((element) => {
			return new Date(element.updateiso);
		});

		// Calcular la fecha máxima y mínima
		const fechaMaxima = new Date(Math.max(...fechasObjeto));
		const fechaMinima = new Date(Math.min(...fechasObjeto));

		return [fechaMaxima, fechaMinima];
	}

	// Se crea una estala de colores a partir de dos colores y los valores minimo y maximo.
	// Para cada valor devolvera un color dentro de esa escala.
	// Devuelve un color en RGB
	colorScale(value, min, max) {
		// Define el rango de colores de rojo a verde
		var colorRange = ['#FF0000', '#00FF00'];

		var minTime = min.getTime();
		var maxTime = max.getTime();
		const valueDateTime = new Date(value).getTime();

		// Crea la escala de colores
		var scale = scaleLinear()
			.domain([minTime, maxTime]) // Rango de valores entre 0 y 1
			.range(colorRange); // Rango de colores

		// Utiliza la escala para obtener un color en función del valor
		return scale(valueDateTime);
	}
}

export default AISTrackEventVesselLineFeatureCreator;
